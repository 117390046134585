<template>
  <div id="findAccount">
    <div class="title">아이디 찾기</div>
    <div class="description">
      회원가입 시, 입력하신 회원정보로 아이디를 확인할 수 있습니다.
    </div>

    <form class="form_wrapper">
      <div class="top_wrapper">
        <label for="name">이름</label>
        <input
          type="text"
          ref="name"
          id="name"
          placeholder="이름"
          v-model="name"
        />
      </div>

      <div class="mid_wrapper">
        <label for="phone1" v-show="findType === 'phone'"
          >전화번호 또는 이메일</label
        >
        <label for="email1" v-show="findType === 'email'"
          >전화번호 또는 이메일</label
        >
        <input
          type="radio"
          id="radioPhone"
          name="findType"
          value="phone"
          v-model="findType"
        />
        <label for="radioPhone" class="label_radio">전화번호</label>
        <input
          type="radio"
          id="radioEmail"
          name="findType"
          value="email"
          v-model="findType"
          checked
        />
        <label for="radioEmail" class="label_radio">이메일</label>
      </div>

      <div class="bottom_wrapper">
        <label></label>
        <!-- 전화번호 -->
        <div v-show="findType === 'phone'">
          <input
            type="tel"
            id="phone1"
            ref="phone1"
            v-model="phone1"
            class="input_tel"
            maxlength="4"
          />
          <span class="tel_text_margin">-</span>
          <input
            type="tel"
            id="phone2"
            v-model="phone2"
            ref="phone2"
            class="input_tel"
            maxlength="4"
          />
          <span class="tel_text_margin">-</span>
          <input
            type="tel"
            id="phone3"
            v-model="phone3"
            ref="phone3"
            class="input_tel"
            maxlength="4"
          />
        </div>
        <!-- 이메일 -->
        <div v-show="findType === 'email'">
          <input
            type="email"
            id="email1"
            ref="email1"
            v-model="email1"
            class="input_email"
            placeholder="이메일을 입력해 주세요."
          />
          <span class="email_text_margin">@</span>
          <input
            type="email"
            id="email2"
            class="input_email input_readonly"
            readonly
            :value="email2"
            v-show="email3 !== ''"
          />
          <input
            type="email"
            id="email2"
            ref="email2"
            v-model="email2"
            class="input_email"
            v-show="email3 === ''"
          />
          <select name="email3" v-model="email3" @change="emailChange($event)">
            <option value="naver.com">네이버</option>
            <option value="gmail.com">구글</option>
            <option value="">직접입력</option>
          </select>
        </div>
      </div>
    <button type="submit" class="btn_confirm" @click.prevent="findAccount()">
      확인
    </button>
    <button class="btn_close" @click.prevent="goLogin()">
      닫기
    </button>
    </form>
  </div>
</template>

<script>
import dateUtil from "@/utils/custom/dateProperty.js";
export default {
  name: "FindAccount",
  components: {},
  data() {
    return {
      findType: "email",
      name: "",
      phone1: "",
      phone2: "",
      phone3: "",
      email1: "",
      email2: "",
      email3: "",
    };
  },
  methods: {
    goLogin() {
      this.$router.push("/user/login");
    },
    emailChange(email3) {
      this.email2 = email3.target.value;
    },
    findAccount() {
      if (!this.name) {
        alert("이름을 입력해 주세요.");
        this.$refs.name.focus();
        return;
      }
      let _phone = "";
      switch (this.findType) {
        case "phone":
          if (!this.phone1 || !this.phone2 || !this.phone3) {
            alert("전화번호를 입력해 주세요.");
            this.$refs.phone1.focus();
            return;
          } else {
            _phone = `${this.phone1}${this.phone2}${this.phone3}`;
          }
          break;
        case "email":
          if (!this.email1 || !this.email2) {
            alert("이메일을 입력해 주세요.");
            this.$refs.email1.focus();
            return;
          } else {
            _phone = `${this.email1}@${this.email2}`;
          }
          break;
      }
      this.$dispatch("findID", {
        // session_id: this.$getters.sessionId,
        session_id: this.getSessionId(),
        name: this.name,
        phone: _phone,
      }).then((res) => {
        if (res.result) {
          alert("회원님의 아이디는 " + res.data[0].account + " 입니다");
          // alert("ID : " + res.data[0].account);
        } else {
          alert(res.message);
        }
      });
    },
    getSessionId() {
      let _sessionId = `${this.getNation()}/${this.getPageId()}/${this.getLocalTime()}/////${this.getUtcTime()}`;
      // console.log(_sessionId);
      return _sessionId;
    },
    getNation() {
      let type = navigator.appName;
      let lang = "";
      let nation = "KOR"; // 기본값 한국
      if (type === "Netscape") {
        lang = navigator.language;
      } else {
        lang = navigator.userLanguage.substr(0, 2);
      }

      // TODO: 국가코드 정해달라고 해야됨
      switch (lang) {
        case "zh": // 중국
          nation = "CHN";
          break;
        case "en": // 미국
          nation = "ENG";
          break;
        default:
          break;
      }
      return nation;
    },
    getPageId() {
      // TODO: 화면아이디 지금 다 바뀌어서 다시 정해야될듯
      return "000000";
    },
    getLocalTime() {
      return dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss");
    },
    getUtcTime() {
      // 1. 현재 시간(Locale)
      const curr = new Date();
      // console.log("현재시간(Locale) : " + curr);

      // 2. UTC 시간 계산
      const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;

      // TODO: 시간 형식 ISO 표준으로 하는게 맞을지 문서에 나온규격대로 짜르는게 맞을지?
      // return new Date(utc).toISOString();
      return dateUtil.format(new Date(utc), "yyyy-MM-dd HH:mm:ss");
    },
  },
};
</script>
<style lang="scss" scoped>
#findAccount {
  width: 870px;
  margin: 0 auto;
  height: fit-content;
  padding-top: 94px;
  // background-color: aqua;
}

.title {
  height: 36px;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -0.72px;
  margin-bottom: 4px;
}

.description {
  height: 24px;
  font-size: 16px;
  letter-spacing: -0.48px;
  text-align: left;
  color: #666666;
  margin-bottom: 14px;
}

.form_wrapper {
  width: 100%;
  height: 300px;
  padding-top: 36px;
  padding-left: 33px;
  padding-bottom: 48px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.top_wrapper {
  display: flex;
  align-items: center;
  height: 42px;
  margin-bottom: 30px;
  // background-color: blue;
}

label {
  width: 147px;
  font-size: 14px;
  letter-spacing: -0.42px;
  color: #666666;
  // background-color: aqua;
}

.label_radio {
  width: 76px;
  margin-left: 8px;
  font-size: 14px;
  letter-spacing: -0.42px;
  color: #666666;
}

input#name {
  width: 262px;
  height: 42px;
  border-radius: 5px;
  border: solid 1px #c8c8c8;
  font-size: 16px;
  padding-left: 15px;
  outline-color: #3a4f3f;
}

.mid_wrapper {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 15px;
  // background-color: aquamarine;
}

.bottom_wrapper {
  align-items: left;
  height: 42px;
  // background-color: pink;
}

.bottom_wrapper > input {
  height: 42px;
  border: solid 1px #979797;
  color: black;
  font-size: 18px;
  text-align: left;
  padding: 10px 15px;
  line-height: 40px;
  overflow: hidden;
}

.btn_confirm {
  float: left;
  width: 96px;
  height: 59px;
  margin-top: 26px;
  margin-right: 90px;
  background-color: #3a4f3f;
  border-radius: 30px;
  color: white;
  outline: none;
  border: none;

  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  cursor: pointer;
}
.btn_close {
  float: left;
  width: 96px;
  height: 59px;
  margin-top: 26px;
  margin-right: 90px;
  background-color: rgb(221, 221, 221);
  border-radius: 30px;
  color: #3a4f3f;
  outline: none;
  border: none;

  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  cursor: pointer;
}

.input_email {
  border-radius: 5px;
  border: solid 1px #c8c8c8;  padding-left: 15px;
  width: 185px;
  height: 42px;
  outline: none;
}

.email_text_margin {
  margin-left: 7px;
  margin-right: 9px;
}

select::-ms-expand {
  display: none;
}

select {
  // position: relative;
  // float: left;
  width: 185px;
  height: 42px;
  border-radius: 5px;
  border: solid 1px #3a4f3f;
  outline: none;
  padding-left: 15px;
  font-size: 16px;
  letter-spacing: -0.48px;
  text-align: left;
  margin-left: 10px;
  color: #333333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../../assets/home/arrow_dropdown.svg") no-repeat;
  background-position: 90% 50%;
}

.input_tel {
  border-radius: 5px;
  border: solid 1px #c8c8c8;
  font-size: 16px;
  text-align: center;
  width: 76px;
  height: 42px;
  outline-color: black;
}

.tel_text_margin {
  margin-left: 6px;
  margin-right: 5px;
}

input::placeholder {
  font-size: 14px;
  letter-spacing: -0.42px;
  color: #c8c8c8;
}

input#email2 {
  width: 185px;
  height: 42px;
  border-radius: 5px;
  border: solid 1px #3a4f3f;
  outline: none;
  padding-left: 15px;
  font-size: 16px;
  letter-spacing: -0.48px;
  color: #333333;
}

.input_readonly {
  background-color: #f2f2f2;
}
</style>
